import restaurant from "./images/giowine.jpg";
import img_card from "./images/hp3.jpg";
import img_nasi from "./images/hp3.jpg";
import img_bucatar from "./images/1.jpg";
import img_music from "./images/2.jpg";
import imgOmSuflet from "./images/vin3.jpg";
import imgOmSufletmb from "./images/vin3.jpg";
import imgheader from "./images/bg2.jpg";
import imgheaderm from "./images/bg2.jpg";
import imgheadermiini from "./images/robloxh.jpg";
import imgheadermiinimb from "./images/robloxm.jpg";
import imgconfirmare from "./images/minioni.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "",
        mireasa: "",
        tata: "Dumitru",
        mama: "",
        copilul: "Dumitru",
        familia: "",
        title: "Invitatie Online",
        data: "29 Aprilie 2023",
        data_confirmare: "",
        savedata: "Vă invit!",
        ani: "Ani",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "mazilud93@gmail.com", 
       tel: "+393664337965",
       phone: "tel:+393664337965",
       viber: "viber://chat?number=%2B393664337965",
       whatsapp: "https://wa.me/+393664337965",
       messenger: "https://www.messenger.com/t/dima.mazilu",
       tel1: "+40740907712",
       phone1: "tel:+40740907712",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+40740907712",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "GIOWine Hotel & Restaurant",
            data: "29 aprilie 2023, sâmbătă, ora 16:00",
            adress: "Strada Crinilor 7, Cricova",
            harta: "https://goo.gl/maps/rQ9k36CJVbJj7wZq9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10856.649726086272!2d28.8571441!3d47.1350837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x50615887ea3c8fbf!2sGIOWine%20Hotel%20%26%20Restaurant!5e0!3m2!1sro!2s!4v1676550063563!5m2!1sro!2s" 
        }
    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "VĂ INVITĂM LA ZIUA NOASTRĂ!",
            message: "Pentru că împlinesc 12 ANI, te invit cu mare drag, să sărbătorim împreună, cu muzică, dans și multe suprize!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "Salutare tuturor!",
            title2: "Am un anunț de făcut:",
            message: 'Încurând schimb prefixul din 2 în 3 și vreau să petrec alături de voi cu această ocazie. O vârstă atât de rotundă, merită să fie sarbătorită cu cei dragi inimii!.',
        },
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Vă invităm alături de cea mai bună bucătăreasă:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Și să gustăm din bucatele delicioase a mânuților de aur!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Sub conducerea muzicală de:",
            title2: "Deejay Vibe",
            message: "Distracția și dansurile sunt asigurate până dimineață!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            img1: imgOmSufletmb,
            title: "Cu mult drag și încântare",
            message: "Vă invit la o petrecere tare!",
            nasii: "Cu muzică și voie bună",
            nasii_nume: "Ne vom distra clar împreună!",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "12 ANI",
            message: "LA MULȚI ANI!",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ], 

}

export default data;